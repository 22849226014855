<template>
  <div class="page">
    <section v-if="success">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/personalCenter' }"
          >个人中心</el-breadcrumb-item
        >
        <el-breadcrumb-item :to="{ path: '/withdraw' }">
          提现
        </el-breadcrumb-item>
        <el-breadcrumb-item>提现成功</el-breadcrumb-item>
      </el-breadcrumb>
      <section>
        <i class="iconfont chenggong"></i>
        <span class="title">提现申请成功，等待处理</span>
        <span class="subTitle">预计两天内到账</span>
        <el-button round @click="$router.push({ path: '/personalCenter' })">
          完成
        </el-button>
      </section>
    </section>

    <section v-else>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/personalCenter' }">
          个人中心
        </el-breadcrumb-item>
        <el-breadcrumb-item>提现</el-breadcrumb-item>
      </el-breadcrumb>
      <header>
        <span>提现</span>
      </header>
      <section>
        <!-- <div class="row">
          <span class="title">提现到：</span>
          <div class="bankList">
            <div
              class="list"
              v-for="(item, i) in popupType"
              :key="i"
              :class="{ active: item.is_default }"
              @click="chonece(item, i)"
            >
              <i class="iconfont" :class="item.icon"></i>
              <span class="name"
                >{{ item.text || item.name }}
                <span v-if="item.card">（{{ item.card | jiam }}）</span>
              </span>
              <span
                v-show="!item.id"
                @click.stop="goBinding(item)"
                class="toBind"
                >去绑定</span
              >
            </div> 


							<div class="list" :class="{ active: paymentMethod == 2 }" @click="$refs.paymentMethod.show('weChat')">
							<i class="iconfont iconweixin"></i>
							<span class="name">微信</span>
							<span class="toBind">去绑定</span>
						</div>
						<div class="list" :class="{ active: paymentMethod == 3 }" @click="paymentMethod = 3">
							<i class="iconfont iconzhifubao"></i>
							<span class="name">支付宝（1234）</span>
						</div>
         </div>
        </div> -->
        <div class="row">
          <span class="title">提现金额：</span>
          <div class="row">
            <el-input
              autocomplete="off"
              v-model="priceNum"
              @input="inputNum"
              placeholder="输入金额"
            ></el-input>
            <span>元</span>
          </div>
        </div>
        <div class="remark">
          <span>可提现金额¥{{ gross }}，</span
          ><span class="a" @click="priceNum = gross">全部转入</span>
        </div>
        <el-button class="confirm" round @click="confirm">确认提现</el-button>
      </section>
    </section>
    <paymentMethod ref="paymentMethod"></paymentMethod>

    <!-- <el-dialog
      width="600px"
      custom-class="back-box"
      top="10vh"
      :visible.sync="isBank"
      :before-close="handleClose"
    >
      <div v-if="form.type == 1">
        <span class="title">添加银行卡</span>
        <div class="chin-zi">
          <div class="left-title">银行名称</div>
          <el-select
            v-model="form.bank_id"
            :disabled="isBin"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="(item, i) in bankList"
              :key="i"
              :label="item.text"
              :value="item.id"
            >
            </el-option>
            <span style="float: right">{{ item.text }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </el-select>
        </div>
        <div class="chin-zi">
          <div class="left-title">开户银行</div>
          <el-input
            style="flex: 1"
            v-model="form.bank_name"
            :readonly="isBin"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="chin-zi">
          <div class="left-title">持卡人姓名</div>
          <el-input
            style="flex: 1"
            v-model="form.username"
            :readonly="isBin"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="chin-zi">
          <div class="left-title">银行卡卡号</div>
          <el-input
            style="flex: 1"
            type="number"
            v-model="form.card"
            :readonly="isBin"
            placeholder="请输入您的卡号"
          ></el-input>
        </div>
      </div>
      <div v-else>
        <span class="title">添加支付宝账号</span>
        <div class="chin-zi">
          <div class="left-title">支付宝账号</div>
          <el-input
            style="flex: 1"
            v-model="form.card"
            :readonly="isBin"
            placeholder="请输入支付宝账号"
          ></el-input>
        </div>
        <div class="chin-zi">
          <div class="left-title">支付宝姓名</div>
          <el-input
            style="flex: 1"
            v-model="form.username"
            :readonly="isBin"
            placeholder="请输入真实姓名"
          ></el-input>
        </div>
      </div>
      <div class="chin-zi">
        <div class="left-title">手机号</div>
        <el-input
          style="flex: 1"
          type="number"
          v-model="form.photo"
          :readonly="isBin"
          placeholder="请输入您的支付宝手机号"
        ></el-input>
      </div>
      <div v-if="!isBin" class="chin-zi">
        <div class="left-title">验证码</div>
        <div class="right-box">
          <el-input
            style="flex: 1"
            type="number"
            v-model="form.code"
            :readonly="isBin"
            placeholder="请填写"
          ></el-input>
          |
          <span class="code-num">
            <span v-if="isCode">{{ codeNum }}秒后重试</span>
            <span v-else @click="getCode(form.photo, 1)">获取验证码</span>
          </span>
        </div>
      </div>
      <div class="chin-zi">
        <div class="left-title2">设置为默认收款账户</div>
        <el-switch
          :disabled="isBin"
          v-model="form.is_default"
          active-color="#03DBC6"
        >
        </el-switch>
      </div>
      <div class="maaotu">
        <div class="checkbox-el">
          <el-checkbox class="readAndAgree" v-model="checked"> </el-checkbox>
          阅读并同意
          <span @click.stop="xin.goUrl('/setting?type=3')"
            >《个税代缴代付协议》</span
          >
        </div>
        <el-button v-if="isBin" :loading="isBtn3" @click="postUpdateDelBank"
          >解除绑定</el-button
        >
        <el-button v-else :loading="isBtn3" @click="addBankCard"
          >确定</el-button
        >
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import paymentMethod from "@components/common/paymentMethod";
export default {
  components: {
    paymentMethod,
  },
  data() {
    return {
      priceNum: "", // 金额
      gross: 0, // 可提现金额
      success: false,
      // defaultPopup: {},
      // isBank: false,
      // checked: false, //协议
      // isBin: false, //只读
      // isEr: false, //微信绑定
      // bankList: [],
      // isBtn3: false,
      // isCode: false, //短信倒计时
      // codeNum: 60,
      // form: {
      //   bank_id: "", //银行id
      //   bank_name: "", //支行名称
      //   username: "", //名称
      //   card: "", //卡号
      //   type: 1, //1银行卡 2支付宝
      //   photo: "", //手机号
      //   code: "", //验证码
      //   is_default: false, //是否默认
      // },
      // popupType: [
      //   {
      //     icon: "yinhangka1",
      //     name: "银行卡",
      //     is_show: 1,
      //     type: 1,
      //     is_default: false,
      //   },
      //   {
      //     icon: "weixin21",
      //     name: "微信",
      //     is_show: 1,
      //     type: 3,
      //     is_default: false,
      //   },
      //   {
      //     icon: "zhifubao1",
      //     name: "支付宝",
      //     is_show: 1,
      //     type: 2,
      //     is_default: false,
      //   },
      // ],
      // paymentMethod: 0,
      // isBoli: false,
    };
  },
  created() {
    this.gross = this.$route.query.gross;
    // this.getPersonalProfit();
    // this.getListBank();
    // this.getBankList();
  },
  filters: {
    // jiam(val) {
    //   if (val) {
    //     val = val.substring(val.length - 4, val.length);
    //   }
    //   return val;
    // },
  },

  methods: {
    // 金额小数点
    inputNum(price) {
      price = price.replace(/[^\d.]/g, "");
      price = price
        .replace(/\.{2,}/g, ".")
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      price = price.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      if (price.indexOf(".") < 0 && price != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        price = parseFloat(price);
      } else if (price.indexOf(".") == 0) {
        //首为小数点，补全
        price = price.replace(/[^$#$]/g, "0.");
        price = price.replace(/\.{2,}/g, ".");
      }
      this.priceNum = price;
    },

    // 确认提现
    async confirm() {
      let price = this.priceNum;
      // let bank_id = this.defaultPopup.id;
      if (price > this.gross) {
        this.xin.message("超过提现金额", "err");
        return;
      }
      if (Number(price) < 1) {
        this.xin.message("最低提现额度不能低于1元", "err");
        return;
      }
      // this.$loading.show();
      let token = localStorage.getItem("token");
      let { code, data, message } = await this.$userApi.getPayment({
        token,
        type: 4,
        price,
      });
      // this.$loading.hide();
      if (code == 200) {
        window.open(data.payment_fail_link);
      } else {
        this.$message({
          type: "error",
          message,
        });
      }
    },

    // async getCode(mobile, type) {
    //   if (!mobile) {
    //     this.xin.message("手机号不能为空!", "err");
    //     return;
    //   }
    //   this.isCode = true;
    //   let res = await this.$loginApi.massCode({ phone: mobile, type });
    //   this.isCode = false;
    //   if (res.code == 200) {
    //     this.$message({ message: "短信已发送,注意查收", type: "success" });
    //     this.codeNum = 60;
    //     this.isCode = true;
    //     let inter = setInterval(() => {
    //       this.codeNum -= 1;
    //       if (this.codeNum <= 0) {
    //         this.isCode = false;
    //         clearTimeout(inter);
    //       }
    //     }, 1000);
    //   }
    // },
    // handleClose(done) {
    //   if (this.isBtn3) return;
    //   if (done) done();
    //   this.isBin = false;
    //   this.checked = false;
    //   setTimeout(() => {
    //     Object.assign(this.$data.form, this.$options.data().form);
    //   }, 300);
    // },
    // alipayVerify() {
    //   //支付宝验证
    //   let { bank_id, bank_name, username, card, photo, code } = this.form;
    //   if (!card) {
    //     this.xin.message("请输入支付宝账号", "err");
    //   } else if (!username) {
    //     this.xin.message("请输入姓名真实姓名", "err");
    //   } else if (!photo) {
    //     this.xin.message("请输入您的支付宝手机号", "err");
    //   } else if (!code) {
    //     this.xin.message("请输入验证码", "err");
    //   } else if (!this.checked) {
    //     this.xin.message("未勾选《个税代缴代付协议》", "err");
    //   } else {
    //     return true;
    //   }
    //   return false;
    // },
    // bankVerify() {
    //   //银行卡验证
    //   let { bank_id, bank_name, username, card, photo, code } = this.form;
    //   if (!bank_id) {
    //     this.xin.message("请选择银行", "err");
    //   } else if (!bank_name) {
    //     this.xin.message("请输入开户银行", "err");
    //   } else if (!username) {
    //     this.xin.message("请输入持卡人姓名", "err");
    //   } else if (!card) {
    //     this.xin.message("请输入银行卡卡号", "err");
    //   } else if (!photo) {
    //     this.xin.message("您还未绑定手机号", "err");
    //   } else if (!code) {
    //     this.xin.message("请输入验证码", "err");
    //   } else if (!this.checked) {
    //     this.xin.message("未勾选《个税代缴代付协议》", "err");
    //   } else {
    //     return true;
    //   }
    //   return false;
    // },
    // async addBankCard() {
    //   //添加银行卡
    //   let form = Object.assign({}, this.form);
    //   if (form.type == 1) {
    //     if (!this.bankVerify()) return;
    //   } else {
    //     if (!this.alipayVerify()) return;
    //   }
    //   form.is_default = form.is_default ? 1 : 0;
    //   this.isBtn3 = true;
    //   let { code } = await this.$userApi.postAddBank(form);
    //   if (code == 200) {
    //     this.isBank = false;
    //     this.xin.message("添加成功!");
    //     // await this.getListBank();
    //   }
    //   this.isBtn3 = false;
    // },
    // async getBankList() {
    //   //获取银行卡
    //   let { data, code } = await this.$userApi.getBankList();
    //   if (code == 200) {
    //     let indexList = [];
    //     for (let i in data) {
    //       indexList.push(...data[i]);
    //     }
    //     // this.indexList = indexList;
    //     this.bankList = indexList;
    //   }
    // },
    // async chonece(item, i) {
    //   if (!item.id || item.is_default) return;
    //   this.popupType.forEach((v) => {
    //     v.is_default = false;
    //   });
    //   this.xin.isLoadding(true);
    //   let { data, code, message } = await this.$userApi.postSetDefaultBank({
    //     id: item.id,
    //   });
    //   this.xin.isLoadding();
    //   if (code == 200) {
    //     item.is_default = true;
    //     this.defaultPopup = item;
    //     this.xin.message("设置成功!");
    //   }
    // },
    // goBinding(item) {
    //   //去绑定
    //   if (item.type == 3) {
    //     this.xin.goUrl("/my/myInfo/addWeChat");
    //   } else {
    //     this.form.type = item.type;
    //     this.isBank = true;
    //   }
    // },
    // async getListBank() {
    //   //获取银行卡
    //   let { data, code } = await this.$userApi.getListBank();
    //   if (code == 200) {
    //     data.forEach((v, i) => {
    //       v.is_default = v.is_default == 1;
    //       if (v.is_default) this.isBoli = true;
    //       // if(v.is_default)this.paymentMethod = v.type;
    //       if (v.type == 1) {
    //         Object.assign(this.popupType[0], v);
    //       } else if (v.type == 2) {
    //         Object.assign(this.popupType[2], v);
    //       } else if (v.type == 3) {
    //         Object.assign(this.popupType[1], v);
    //       }
    //     });
    //     this.popupType.forEach((v) => {
    //       if (v.is_default == 1) {
    //         this.defaultPopup = v;
    //       }
    //     });
    //   }
    // },
    // async getPersonalProfit() {
    //   //获取收益信息
    //   let { data, code } = await this.$userApi.getPersonalProfit({
    //     year: new Date().getFullYear(), //年
    //     month: new Date().getMonth() + 1, //月
    //   });
    //   if (code == 200) {
    //     this.earnings = data;
    //     this.gross = data.withdrawal;
    //     // this.earningsList = data.lists;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

/deep/.back-box {
  .erwm-box {
    text-align: center;
    img {
      width: 183px;
      height: 183px;
      margin: 20px auto;
    }
    .title-erw {
      font-size: 12px;
      color: #352b5c;
    }
  }
  .readAndAgree {
    margin-right: 10px;
  }
  .chin-zi {
    display: flex;
    width: 100%;
    height: 62px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f2f2f2;

    .left-title {
      color: #333;
      font-weight: 400;
    }
    .el-input__inner {
      border: none;
      text-align: right;
    }
    .el-input.is-disabled .el-input__icon {
      cursor: default;
    }
    .el-input.is-disabled .el-input__inner {
      color: #606266;
      background-color: #0000;
      cursor: default;
    }
    .right-box {
      flex: 1;
      display: flex;
      align-items: center;
      color: #8f8f8f;
      .code-num {
        cursor: pointer;
        margin-left: 10px;
        color: $--themeColor;
      }
    }
  }
  .el-dialog__body {
    box-sizing: border-box;
    padding: 50px 50px;
    .title {
      font-size: 24px;
      color: #333333;
      margin-bottom: 30px;
    }
    .code {
      margin-top: 20px;
      .el-input-group__append {
        background: none;
        border: none;
        color: $--themeColor;
        cursor: pointer;
      }
    }
    .maaotu {
      margin-top: 50px;
      text-align: center;
      .el-button {
        margin-top: 10px;
        border: none;
        height: 44px;
        font-size: 18px;
        color: white;
        background-color: $--themeColor;
        border-radius: 44px;
        width: 360px;
      }
    }
  }
}

.page {
  background-color: #f2f2f2;
  > section {
    flex: 1;
    display: flex;
    flex-direction: column;
    > header {
      box-sizing: border-box;
      padding: 0 33px;
      display: flex;
      align-items: center;
      height: 61px;
      background-color: #dbfbf8;
      font-size: 24px;
      color: $--themeColor;
    }
    > section {
      display: flex;
      flex-direction: column;
      min-height: 500px;
      background-color: white;
      box-sizing: border-box;
      padding: 30px;
      flex: 1;
      > .row {
        align-items: center;
        margin-top: 30px;
        &:first-child {
          margin-top: 0;
        }
        > .title {
          width: 90px;
          font-size: 18px;
          color: #8f8f8f;
          margin-right: 34px;
          text-align-last: justify;
        }
        > .bankList {
          display: flex;
          > .list {
            cursor: pointer;
            border: 1px dashed #8f8f8f;
            height: 44px;
            box-sizing: border-box;
            padding: 0 17px;
            display: flex;
            align-items: center;
            margin-left: 30px;
            overflow: hidden;
            &:hover {
              border-color: $--themeColor;
            }
            &:first-child {
              margin-left: 0;
            }
            &.active {
              border-color: $--themeColor;
              &::after {
                content: "﹂";
                position: absolute;
                background-color: $--themeColor;
                color: white;
                padding: 0 40px 10px;
                transform: rotate(-40deg);
                bottom: -5px;
                right: -40px;
              }
            }
            > .iconfont {
              font-size: 28px;
              &.yinhangka1 {
                color: #ffcc01;
              }
              &.weixin21 {
                color: #6bc839;
              }
              &.zhifubao1 {
                color: #02a9f1;
              }
            }
            > .name {
              margin-left: 10px;
              font-size: 14px;
              color: #333333;
            }
            > .toBind {
              height: 22px;
              box-sizing: border-box;
              border: 1px solid $--themeColor;
              color: $--themeColor;
              background-color: white;
              padding: 0 8px;
              font-size: 12px;
              line-height: 20px;
              margin-left: 20px;
              border-radius: 22px;
            }
          }
        }
        > .row {
          align-items: center;
          ::v-deep {
            .el-input {
              box-sizing: border-box;
              // border: 1px solid #d9d9d9;
              border-radius: 0;
              width: 190px;
              background: none;
            }
          }
          > span {
            font-size: 14px;
            color: #333333;
            margin-left: 10px;
          }
        }
      }
      > .remark {
        font-size: 16px;
        color: #8f8f8f;
        margin-top: 10px;
        margin-left: 124px;
        > .a {
          cursor: pointer;
          color: #352b5c;
        }
      }
      // 以下为提现成功
      .chenggong {
        font-size: 150px;
        color: #ffb819;
        margin: 80px auto 0;
      }
      > .title {
        margin: 52px auto 0;
        font-size: 18px;
        line-height: 32px;
        color: #333333;
      }
      > .subTitle {
        margin: 20px auto 0;
        font-size: 14px;
        color: #8f8f8f;
      }
      ::v-deep {
        .el-button {
          &.confirm {
            width: 190px;
            margin-top: 40px;
            margin-left: 124px;
          }
          border: none;
          margin: 40px auto 0;
          border-radius: 44px;
          height: 44px;
          font-size: 18px;
          background-color: $--themeColor;
          color: white;
          width: 360px;
        }
      }
    }
  }
}
</style>
