<template>
  <el-dialog
    width="800px"
    custom-class="paymentMethod"
    :visible.sync="showPaymentMethod"
  >
    <template v-if="type == 'alipay'">
      <span class="title">添加支付宝账号</span>
      <div class="form">
        <field
          label="支付宝账号"
          v-model="alipayForm.account"
          placeholder="请输入支付宝账号"
        ></field>
        <field
          label="支付宝姓名"
          v-model="alipayForm.name"
          placeholder="请输入真实姓名"
        ></field>
        <field
          label="手机号"
          v-model="alipayForm.mobile"
          placeholder="请填写您的支付宝手机号"
        ></field>
        <field label="验证码" v-model="alipayForm.code" placeholder="请填写">
          <template #right>
            <div
              style="
                width: 1px;
                height: 14px;
                margin: 0 10px;
                background: #999999;
              "
            ></div>
            <span style="color: $--themeColor; cursor: pointer"
              >获取验证码</span
            >
          </template>
        </field>
        <field
          type="switch"
          label="设置为默认收款账户"
          v-model="alipayForm.default"
        ></field>
      </div>
    </template>
    <template v-else-if="type == 'weChat'">
      <span class="title">添加微信账号</span>
      <div class="form">
        <field
          label="微信用户名"
          v-model="weChatForm.username"
          placeholder="请输入真实姓名"
        ></field>
      </div>
      <template v-if="step === 1">
        <img
          class="weChatQR"
          src="@image/QR-Code.png"
          alt="微信验证二维码"
          @click="step++"
        />
        <span class="remark">通过微信扫描识别二维码</span>
      </template>
      <template v-else-if="step === 2">
        <img class="weChatQR" src="@image/QR-Pass.png" alt="微信授权成功" />
        <span class="remark">已授权医付通微信付款</span>
        <field
          type="switch"
          topBorder
          style="margin-top: 20px"
          label="设置为默认收款账户"
          v-model="alipayForm.default"
        ></field>
      </template>
    </template>
    <template v-else-if="type == 'bankCard'">
      <span class="title">添加银行卡</span>
      <div class="form">
        <field
          type="select"
          label="银行名称"
          v-model="bankCardForm.bankName"
          :options="[
            { value: 1, label: '中国银行' },
            { value: 2, label: '外国银行' },
          ]"
          placeholder="请选择"
        ></field>
        <field
          label="开户银行"
          v-model="bankCardForm.bankAccount"
          placeholder="请输入"
        ></field>
        <field
          label="持卡人姓名"
          v-model="bankCardForm.name"
          placeholder="请输入"
        ></field>
        <field
          label="银行卡卡号"
          v-model="bankCardForm.bankCardNumber"
          placeholder="请填写您的卡号"
        ></field>
        <field
          type="switch"
          label="设置为默认收款账户"
          v-model="bankCardForm.default"
        ></field>
      </div>
    </template>
    <el-checkbox class="readAndAgree" v-model="readAndAgree"
      >阅读并同意《个税代缴代付协议》</el-checkbox
    >
    <el-button
      class="confirm"
      :class="{ readAndAgree }"
      round
      @click="addPaymentMethod()"
      >确定</el-button
    >
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      showPaymentMethod: false,
      type: null,
      alipayForm: {
        account: null,
        name: null,
        mobile: null,
        code: null,
        default: false,
      },
      weChatForm: {
        username: null,
      },
      step: 1,
      bankCardForm: {
        bankName: null,
        bankAccount: null,
        name: null,
        bankCardNumber: null,
        default: false,
      },
      readAndAgree: false,
    };
  },
  methods: {
    show(type = "alipay", form = {}) {
      this.type = type;
      this[`${type}Form`] = {
        ...this[`${type}Form`],
        ...form,
      };
    },
  },
  watch: {
    showPaymentMethod(val) {
      if (!val) {
        // 重置data
        Object.assign(this.$data, this.$options.data.call(this));
      }
    },
    type(val) {
      if (val) {
        this.showPaymentMethod = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

::v-deep {
  .paymentMethod {
    box-sizing: border-box;
    padding: 50px;
  }
}
.paymentMethod {
  .title {
    font-size: 24px;
    color: #333333;
  }
  .form {
    margin-top: 10px;
  }
  .readAndAgree {
    margin: 50px auto 0;
  }
  .confirm {
    width: 360px;
    height: 44px;
    border-radius: 44px;
    background-color: #dbfbf8;
    color: white;
    font-size: 18px;
    margin: 10px auto 0;
    border: none;
    &.readAndAgree {
      background-color: $--themeColor;
    }
  }
  .weChatQR {
    margin: 20px auto 0;
    width: 183px;
    height: 183px;
    object-fit: contain;
    margin-top: 21px;
  }
  .remark {
    margin: 10px auto 0;
    font-size: 12px;
    color: #352b5c;
  }
}
</style>
